<template>
  <div>
    <div>
      <a-tabs type="card" class="w-full"
              @change="handlerTypeChange"
              :activeKey="activeTab">
        <a-tab-pane key="1" tab="城市商品上下架"></a-tab-pane>
        <a-tab-pane key="2" tab="上架产品库存检查"></a-tab-pane>
      </a-tabs>
    </div>

    <template v-if="activeTab == 1">
      <div class="search-bar flex justify-between items-center">
        <a-form-model ref="searchRef" style="flex:1;"
                      :model="searchForm"
                      :label-col="labelCol"
                      :wrapper-col="wrapperCol">
          <a-row>
            <a-col :span="6">
              <a-form-model-item prop="type" label="城市">
                <a-select v-model="searchForm.outlets_city_adcode" placeholder="选择城市"
                    allowClear
                    @change="handlerSearchSale">
                  <a-select-option
                    v-for="(item, index) in manageCityList"
                    :key="index"
                    :value="item.adcode"
                  >{{ item.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item prop="type" label="产品属性">
                <a-select v-model="searchForm.type" placeholder="产品属性"
                    @change="handlerSearchSale">
                  <a-select-option value>全部</a-select-option>
                  <a-select-option
                    v-for="item in Object.keys(goodsType)"
                    :value="item"
                    :key="item"
                    >{{ goodsType[item] }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item prop="goods_name" label="商品名">
                <a-input allowClear
                         v-model="searchForm.goods_name"
                         placeholder="商品名称"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item prop="sku_number"
                                 label="SKU码">
                <a-input allowClear
                         v-model="searchForm.sku_number"
                         placeholder="SKU码"></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <div>
          <a-button class="ml-8" :loading="listLoading" @click="handlerSearchSale" type="primary">搜索</a-button>
          <a-button class="ml-2" @click="resetForm1">重置</a-button>
        </div>
      </div>

      <base-table ref="tableRef"
                  :columnsData="columns"
                  :rowKey="rowKey"
                  :tableData="tableData"
                  :row-selection="rowSelection"
                  :total="total"
                  :getListFunc="initDataSale"
                  :page.sync="searchForm.page"
                  :pageSize.sync="searchForm.page_count">
        <template #toolbar>
          <!-- <div class="flex items-center mb-2">
            <a-select v-model="searchForm.outlets_city_adcode" style="width: 100px;" @change="searchData">
              <a-select-option
                v-for="(item, index) in manageCityList"
                :key="index"
                :value="item.adcode"
              >{{ item.name }}</a-select-option>
            </a-select>
            <h4 class="text-lg font-bold ml-4 mb-0">城市门店商品上架</h4>
          </div> -->
          <div class="flex justify-between">
            <div>
              <template v-if="hasAuthOnsale && rowSelection">
                <a-button type="link" class="p-0 ml-1" :disabled="!rowSelection.selectedRowKeys.length"
                        @click="handlerAddGoods(null,true)">批量上架到全部网点</a-button>
                <a-button type="link" class="p-0 ml-6" :disabled="!rowSelection.selectedRowKeys.length"
                        @click="handlerRemoveGoods(null,true)">批量从全部网点下架</a-button>
              </template>
            </div>
            <div>
              <a-button type="link" class="p-0 ml-1" 
                      @click="handlerSelectOutlets">查看具体网点</a-button>
            </div>
          </div>
        </template>
        <template #operation="{record}">
          <a-button type="link" 
              v-if="hasAuthOnsale" @click="handlerEdit(record)">编辑</a-button>
          <a-button type="link" 
              v-else @click="handlerDetail(record)">查看</a-button>
        </template>
      </base-table>

      <!-- 批量上架非蛋糕（多个产品、全部网点） -->
      <add-goods-to-allshops v-if="isShowAddGoodsToAll"
            :show.sync="isShowAddGoodsToAll"
            :adcode="searchForm.outlets_city_adcode"
            :goodIdList="selectedGoodIdList"
            @ok="handlerAddGoodsOK" />

      <!-- 编辑某产品的上下架（一个产品、多个网点） -->
      <edit-good-in-someshops v-if="isShowEditGoodInSome"
            :show.sync="isShowEditGoodInSome"
            :adcode="searchForm.outlets_city_adcode"
            :goodItem="activeGood"
            @ok="handlerEditGoodOK" />
      <!-- 查看产品在各个网点的上架情况 -->
      <edit-good-shops v-if="isShowDetail"
          :show.sync="isShowDetail"
          :adcode="searchForm.outlets_city_adcode"
          :goodId="activeGood.goods_id"
          :goods="activeGood"
          :isCake="activeGood.type == 3"
          onlyRead />

      <!-- 选择网点进行管理 -->
      <select-shop v-if="isShowOutlets"
            :show.sync="isShowOutlets"
            :adcode="searchForm.outlets_city_adcode" />
    </template>


    <div v-if="activeTab == 2">
      <div class="search-bar flex justify-between items-center">
        <a-form-model ref="searchRef2" style="flex:1;"
                      class="flex justify-between items-center"
                      :model="searchForm2"
                      :label-col="labelCol"
                      :wrapper-col="wrapperCol">
          <a-form-model-item style="flex:1;margin-bottom:0;" prop="type" label="城市">
            <a-select v-model="searchForm2.outlets_city_adcode" placeholder="选择城市"
                allowClear>
              <a-select-option
                v-for="(item, index) in manageCityList"
                :key="index"
                :value="item.adcode"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item style="flex:1;margin-bottom:0;" prop="type" label="产品">
            <a-select placeholder="输入商品名"
                      v-model="searchForm2.goods_id"
                      show-search allowClear
                      :default-active-first-option="false"
                      :show-arrow="false"
                      :filter-option="false"
                      :not-found-content="null"
                      @search="handlerSuggestGoods">
              <a-select-option v-for="item in suggestGoodsList"
                       :key="item.goods_id"
                       :value="item.goods_id">{{ item.goods_name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item style="flex:1;margin-bottom:0;" label="网点类型" prop="outlets_type">
            <a-select v-model="searchForm2.outlets_type" style="width: 100%">
              <a-select-option value="">全部</a-select-option>
              <a-select-option value="1">门店</a-select-option>
              <a-select-option value="2">配送站</a-select-option>
            </a-select>
          </a-form-model-item>
          <div class="ml-4">
            异常库存：
            <a-checkbox v-model="check_current">当前库存</a-checkbox>
            <a-checkbox class="ml-1" v-model="check_daily">每日库存</a-checkbox>
            <a-checkbox class="ml-1" v-model="check_total">剩余总库存</a-checkbox>
          </div>
          <a-switch v-model="goodStatusNormal" 
            @change="(b)=>onChangeExpress(b,record)" 
            checked-children="正常产品" un-checked-children="禁用产品" />
        </a-form-model>
        <div>
          <a-button class="ml-8" :loading="listLoading" @click="handlerSearchStock" type="primary">搜索</a-button>
          <a-button class="ml-2" @click="resetForm2">重置</a-button>
        </div>
      </div>
      <base-table ref="tableRef2"
                  :columnsData="columns_stock"
                  :tableData="tableData2"
                  :total="total2"
                  :getListFunc="initDataStock"
                  :page.sync="searchForm2.page"
                  :pageSize.sync="searchForm2.page_count">
        <template #operation="{record}">
          <a-button v-if="hasAuthStock" type="link" @click="handlerEditStock(record)">编辑</a-button>
          <!-- <a-button v-if="record.goods_type!=3" type="link" @click="handlerEditStock(record)">编辑</a-button> -->
          <!-- <template v-else>——</template> -->
        </template>
      </base-table>

      <!-- 编辑库存 -->
      <edit-sku-stock v-if="isShowEditGoodStock"
            :show.sync="isShowEditGoodStock"
            :skuData="activeGood"
            @ok="initDataStock" />
    </div>
      
  </div>
</template>

<script>

import AddGoodsToAllshops from "./components/add-goods-to-allshops.vue"
import EditGoodInSomeshops from "./components/edit-good-in-someshops.vue"
import SelectShop from "./components/select-shop.vue"
import EditGoodShops from "./components/edit-good-shops.vue"
import EditSkuStock from "./components/edit-sku-stock.vue"
import { 
  getRegionalGoodsList, 
  addRegionalCakeGoods,
  addRegionalOtherGoods,
  removeRegionalGoods,
  getGoodsOnSaleStock,
} from "@/api/regional/goods";
import { getGoodsList } from "@/api/goods";

import pageData from "./columns";

import { goodsType, goodsStatusList } from "@/utils/type";
import { isCityManage, getMarketCityList, checkAuth } from "@/utils/authData";

export default {
  components: {
    AddGoodsToAllshops,
    EditGoodInSomeshops,
    SelectShop,
    EditGoodShops,
    EditSkuStock
    // AddShopGoods,
  },
  data() {
    return {
      ...pageData,
      goodsType,
      statusTypeList: goodsStatusList,
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },

      activeTab: "1",
      isCityManage: isCityManage(),
      manageCityList: [],

      hasAuthOnsale: checkAuth("regional-shop-sell:goods"), // 操作上下架
      hasAuthStock:  checkAuth("regional-shop-sell:stock"), // 操作库存

      searchForm: {
        outlets_city_adcode: undefined,
        sale_status: "1",
        goods_name: "",
        sku_number: "",
        type: "",
        page: 1,
        page_count: 40,
      },
      total: 0,
      tableData: [],

      rowSelection: null,
      baseRowSelection: {
        selectedRowKeys: [],
        onSelect: (record, selected) => {
          if (selected) {
            this.rowSelection.selectedRowKeys.unshift(record.goods_id);
          } else {
            this.rowSelection.selectedRowKeys =
              this.rowSelection.selectedRowKeys.filter(
                (el) => el != record.goods_id
              );
          }
        },
        onSelectAll: (selected, selectedRows) => {
          if (selected) {
            this.rowSelection.selectedRowKeys = selectedRows.map(
              (el) => el.goods_id
            );
          } else {
            this.rowSelection.selectedRowKeys = [];
          }
        },
      },
      selectedGoodIdList: [],
      isShowAddGoodsToAll: false,
      activeGood: null,
      isShowEditGoodInSome: false,

      isShowEditGoodStock: false,

      isShowOutlets: false,
      isShowDetail: false,

      // 库存检查
      searchForm2: {
        outlets_city_adcode: undefined,
        goods_id: undefined,
        outlets_type: "",
        filtrate_not_stock: "",
        filtrate_not_daily_refresh_stock: "",
        filtrate_not_total_stock: "",
        page: 1,
        page_count: 40,
      },
      total2: 0,
      tableData2: [],
      suggestGoodsList: [],
      timerSuggestGoods: null,
      check_current: false,
      check_daily: false,
      check_total: false,
      goodStatusNormal: true,

      loading: false,
      listLoading: false,
    };
  },
  async mounted() {
    this.manageCityList = await getMarketCityList()

    if(this.manageCityList.length == 1){
      this.searchForm.outlets_city_adcode = this.manageCityList[0].adcode
      this.searchForm2.outlets_city_adcode = this.manageCityList[0].adcode
    }

    if(this.hasAuthOnsale){
      this.rowSelection = this.baseRowSelection
    }

    this.handlerTypeChange(1)
  },
  methods: {

    handlerTypeChange(val){
      this.activeTab = String(val)
      if(this.activeTab == 1){
        if(this.searchForm.outlets_city_adcode){
          this.handlerSearchSale()
        }
      }
      if(this.activeTab == 2){
        if(this.searchForm2.outlets_city_adcode){
          // this.handlerSearchStock()
        }
      }
    },
    handlerSearchSale(){
      this.searchForm.page = 1
      this.initDataSale()
    },
    async initDataSale() {
      this.listLoading = true
      const { data, code } = await getRegionalGoodsList(this.searchForm);
      this.listLoading = false
      if (code === 0) {
        this.tableData = data.list;
        this.total = data.total_count
      }
    },
    resetForm1() {
      this.$refs.searchRef.resetFields();
      this.initDataSale();
    },

    handlerEditStock(row){
      this.activeGood = row
      this.isShowEditGoodStock = true
    },

    handlerEdit(row){
      this.activeGood = row
      this.isShowEditGoodInSome = true
    },
    handlerDetail(row){
      this.activeGood = row
      this.isShowDetail = true
    },

    async handlerRemoveGoods(gid, isMulti) {
      let ids
      if(isMulti){
        ids = (this.rowSelection.selectedRowKeys || []).map(id=>{
          return { goods_id: id }
        })
      }else{
        ids = [ {goods_id: gid} ]
      }
      if(ids && ids.length){
        let self = this
        this.$confirm({
          title: ids.length>1 ? "对全部门店和配送站下架这些商品？" : "对全部门店和配送站下架这个商品？",
          async onOk () {
            const { code } = await removeRegionalGoods({
              outlets_city_adcode: self.searchForm.outlets_city_adcode,
              off_shelves_goods_data: JSON.stringify(ids)
            });
            if (code == 0) {
              self.$message.success("已下架")
              self.rowSelection.selectedRowKeys = []
              self.initDataSale()
            }
          },
          onCancel () { },
        })
      }
    },
    handlerSelectOutlets(){
      this.isShowOutlets = true
    },

    handlerAddGoods(gid, isMulti) {
      let selectids = []
      if(isMulti){
        selectids = this.rowSelection.selectedRowKeys || []
      }else{
        selectids = [ gid ]
      }
      if(selectids.length){
        let otherIds = this.tableData.filter(gd=>{
          return gd.type != 3
        }).map(gd=>{
          return gd.goods_id
        })
        let hasOther = false
        while(otherIds.length){
          let otherid = otherIds.pop()
          if(selectids.includes(otherid)){
            hasOther = true
            break
          }
        }
        this.selectedGoodIdList = selectids
        // 如果纯蛋糕 直接添加；否则提示设置库存
        if(!hasOther){
          this.addOnlyCakes()
        }else{
          this.isShowAddGoodsToAll = true
        }
      } 
    },

    async addOnlyCakes(){
      if(this.loading) return

      let self = this
      this.$confirm({
        title: "确定上架商品到全部门店和配送站？",
        async onOk () {
          let cake_goods_list = self.selectedGoodIdList.map(id=>{
            return { goods_id: id }
          })
          self.handlerAddGoodsOK({
            cake_goods_list: cake_goods_list
          })
        },
        onCancel () {
          console.log("Cancel")
        },
      })
    },

    // 批量上架到全部网点
    async handlerAddGoodsOK(data) {
      if(this.loading) return

      let res1 = null
      let res2 = null
      if(data.cake_goods_list && data.cake_goods_list.length){
        this.loading = true
        res1 = await addRegionalCakeGoods({
          outlets_city_adcode: this.searchForm.outlets_city_adcode,
          add_goods_data: JSON.stringify(data.cake_goods_list)
        })
        this.loading = false
      }
      if(data.other_sku_list && data.other_sku_list.length){
        this.loading = true
        res2 = await addRegionalOtherGoods({
          outlets_city_adcode: this.searchForm.outlets_city_adcode,
          add_sku_data: JSON.stringify(data.other_sku_list)
        })
        this.loading = false
      }

      if(!res1 && !res2){
        this.$message.info("没有变更的产品")
        return
      }
      const code1 = res1 ? res1.code : 0
      const code2 = res2 ? res2.code : 0
      if(code1 == 0 && code2 == 0){
        this.isShowAddGoodsToAll = false
        this.$message.success("已上架")
        this.rowSelection.selectedRowKeys = []
        this.initDataSale()
      }
    },

    // 编辑一个产品的网点
    async handlerEditGoodOK(data) {
      if(this.loading) return
        
      let outlets_city_adcode = this.searchForm.outlets_city_adcode
      let goods_id = this.activeGood.goods_id

      let res1 = null
      let res2 = null
      
      // 下架
      if(data.offIds.length){
        this.loading = true
        res2 = await removeRegionalGoods({
          outlets_city_adcode: outlets_city_adcode,
          outlets_ids: data.offIds.join(","),
          off_shelves_goods_data: JSON.stringify([{
            goods_id: goods_id
          }])
        });
        this.loading = false
      }
      
      // 上架（同时设置新上架的网点的库存）
      if(data.onIds.length){
        if(data.isCake){ // 蛋糕上架
          this.loading = true
          res1 = await addRegionalCakeGoods({
            outlets_city_adcode: outlets_city_adcode,
            outlets_ids: data.onIds.join(","),
            add_goods_data: JSON.stringify([{
              goods_id: goods_id
            }])
          })
          this.loading = false
        }else{ // 非蛋糕上架
          this.loading = true
          res1 = await addRegionalOtherGoods({
            outlets_city_adcode: outlets_city_adcode,
            outlets_ids: data.onIds.join(","),
            add_sku_data: JSON.stringify(data.other_sku_list)
          })
          this.loading = false
        }
      }
      
      if(!res1 && !res2){
        this.$message.info("您没有变更上下架网点")
        return
      }
      const code1 = res1 ? res1.code : 0
      const code2 = res2 ? res2.code : 0
      if(code1 == 0 && code2 == 0){
        this.isShowEditGoodInSome = false
        this.$message.success("操作成功")
        this.initDataSale()
      }
    },

    // ---------------- 检查库存
    handlerSearchStock(){
      this.searchForm2.page = 1
      this.initDataStock()
    },
    async initDataStock(){
      const params = {
        ...this.searchForm2,
        filtrate_not_stock: this.check_current ? 2 : 1,
        filtrate_not_daily_refresh_stock: this.check_daily ? 2 : 1,
        filtrate_not_total_stock: this.check_total ? 2 : 1,
        goods_status: this.goodStatusNormal ? 1 : -1
      }
      this.listLoading = true
      const { data, code } = await getGoodsOnSaleStock(params);
      this.listLoading = false
      if (code === 0) {
        this.tableData2 = data.list;
        this.total2 = data.total_count
      }
    },

    resetForm2() {
      this.$refs.searchRef2.resetFields();
      this.initDataStock();
    },

    async handlerSuggestGoods (keyword) {
      if(this.timerSuggestGoods){
        clearTimeout(this.timerSuggestGoods)
        this.timerSuggestGoods = null
      }
      this.timerSuggestGoods = setTimeout(()=>{
        this.getSuggestGoods(keyword)
      }, 100)
    },
    async getSuggestGoods(keyword){
      keyword = (keyword || "").replace(/^\s*/g,"").replace(/\s*$/g,"")
      if(!keyword){
        this.suggestGoodsList = []
        return
      }
      const { data, code } = await getGoodsList({
        goods_name: keyword,
        status: this.goodStatusNormal ? 1 : -1,
        page_count: 10,
      })
      if (code === 0) {
        this.suggestGoodsList = data.list
      }
    },
    
  },
};
</script>

<style lang="less">
.goods-search-bar {
  margin-bottom: 16px;
  padding: 15px 24px 0;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  .ant-form-item {
    margin-bottom: 5px;
  }
}
</style>
