<template>
  <a-modal
    :visible.sync="show"
    :title="title"
    destroyOnClose
    width="950px"
    :footer="null"
    @cancel="$emit('update:show', false)"
  >
    <div class="search-bar">
      <div class="flex justify-center mb-1">
        <div class="flex items-center">
          <span class="mr-4">搜索网点名</span>
          <a-input-search style="width:300px;" placeholder="请输入网点名" allowClear
              v-model="searchForm.name" @search="search" />
        </div>
      </div>
    </div>

    <div class="flex">
      <div style="width:230px;">
        <div :class="activeGroupId == group.id ? 'group-item group-item-active' : 'group-item' "
          v-for="(group, index) in groupList"
          :key="index"
          :value="group.id"
          @click="selectGroup(group.id)"
        >{{ group.name }}（在售{{group.onsale}}/{{group.total}}）</div>
      </div>
      <div style="flex:1;">
        <base-table class="mb-3" 
                    id="shoplist" ref="shoplist" 
                    rowKey="outlets_id"
                    :columnsData="baseColumns"
                    :tableData="searchShopList"
                    :noPage="true"
                    :addHeight="90">
            <template #operation="{record}">
              <template v-if="disabled">
                <a-tag v-if="record.isOnSale" class="mr-0" color="green">在售</a-tag>
                <a-tag v-else class="mr-0" color="grey">未上架</a-tag>
              </template>
              <a-switch v-if="!disabled" checked-children="在售" un-checked-children="下架"
                v-model="record.isOnSale" @change="switchShopOnSale" />
            </template>
        </base-table>
      </div>
        
    </div>

    <div v-if="!disabled" style="text-align: right;">
      <div>
        <a-button class="p-0 mr-4" type="link" 
            :disabled="disableMulti"
            @click="handlerGroupOnSale(true)">本组全部上架</a-button>
        <a-button class="p-0 mr-1" type="link" 
            :disabled="disableMulti"
            @click="handlerGroupOnSale(false)">本组全部下架</a-button>
      </div>
      <div class="mt-2 mb-2">
        <a-button @click="$emit('update:show', false)">取消</a-button>
        <a-button class="ml-4" type="primary" @click="handleOk">确定</a-button>
      </div>
    </div>

  </a-modal>
</template>

<script>
import { formatOutletsType, formatBusinessStatus } from "@/utils/type"
import {
  getGoodsSkuInAllShopsStatus
} from "@/api/regional/goods";
import {
  getGroupList
} from "@/api/outlets"

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    goodId: {
      type: [String, Number],
      default: "",
    },
    goods: {
      type: Object,
      default: null,
    },
    isCake: {
      type: Boolean,
      default: false,
    },
    adcode: {
      type: [String, Number],
      default: "",
    },
    shopList: {
      type: Array,
      default: null,
    },
    onlyRead: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      disabled: this.onlyRead,

      title: "产品上架情况",

      wrapperCol: { span: 16 },
      labelCol: { span: 8 },
      searchForm: {
        outlets_city_adcode: this.adcode,
        name: "",
        type: this.isCake ? 0 : 1,
        status: "3",
        page: 1,
        page_count: 200,
      },
      baseColumns: [
        {
          title: '类型',
          dataIndex: 'outlets_type',
          align: 'center',
          width: 80,
          slots: {
            customRender: 'outlets_type'
          },
          slotsType: 'format',
          slotsFunc: (val) => formatOutletsType(val)
        },
        {
          title: '状态',
          dataIndex: 'outlets_status',
          align: 'center',
          width: 80,
          slots: {
            customRender: 'outlets_status'
          },
          slotsType: 'format',
          slotsFunc: (val) => formatBusinessStatus(val)
        },
        {
          title: '网点名称',
          dataIndex: 'outlets_name',
          align: 'center',
          // width: 200,
        },
        {
          title: '在售/下架',
          dataIndex: 'operation',
          width: 120,
          slots: { customRender: 'operation' },
          align: 'center'
        },
      ],
      columns: [],
      tableData: [],

      disableMulti: false,
      allShopList: [],
      groupList: [],
      activeGroupId: "",
      allOnSale: 0,
      allTotal: 0,
      groupShopList: [],
      searchShopList: [],

    }
  },

  mounted () {
    if(this.goods){
      this.title = this.goods.goods_name
    }

    this.initData()
  },
  methods: {
    search(){
      if(this.searchForm.name){
        this.disableMulti = true
        this.searchShopList = this.groupShopList.filter(shop=>{
          return shop.outlets_name.indexOf(this.searchForm.name) > -1
        })
      }else{
        this.disableMulti = false
        this.searchShopList = this.groupShopList
      } 
    },
    reset(){
      this.searchForm.name = ""
      this.search()
    },
    async initData () {
      if(!this.goodId) return;

      await this.getGroupList()

      if(this.shopList && this.shopList.length){
        this.allShopList = this.shopList
      }else{
        this.allShopList = await this.getShopList()
      }
      
      this.updateSaleCount()
      this.selectGroup("")
    },

    async getShopList () {
      if(!this.goodId) return [];

      let list 
      const { data, code } = await getGoodsSkuInAllShopsStatus({
        outlets_city_adcode: this.adcode,
        goods_id: this.goodId,
        type: this.searchForm.type,
      })
      if (code == 0) {
        list = data.list.map(shop=>{
          shop.isOnSale = shop.sale_status == 1
          return shop
        })
      }
      return list || []
    },

    
    selectGroup(groupId){
      this.activeGroupId = groupId

      if(!groupId){
        this.groupShopList = this.allShopList
      }else{
        this.groupShopList = this.allShopList.filter(shop=>shop.outlets_group_id == groupId)
      }
      this.search()
    },

    async getGroupList () {
      const { data, code } = await getGroupList({
        outlets_city_adcode: this.adcode
      })
      if (code == 0) {
        let groupList = data.list || []
        groupList.unshift({
          id: "",
          name: "全部"
        })
        groupList.push({
          id: "0",
          name: "未分组"
        })

        this.groupList = groupList
      }
    },

    updateSaleCount(){
      this.groupList = this.groupList.map(group=>{
        group.onsale = this.allShopList.filter(shop=>{
          if(!group.id) return shop.isOnSale
          else return shop.outlets_group_id == group.id && shop.isOnSale
        }).length
        group.total = this.allShopList.filter(shop=>{
          if(!group.id) return true
          else return shop.outlets_group_id == group.id
        }).length

        return group
      })
    },

    switchShopOnSale(){
      this.updateSaleCount()
    },

    handlerGroupOnSale(isSale){
      this.groupShopList.map(el=>el.isOnSale = isSale)
      this.updateSaleCount()
    },

    handleOk () {
      this.$emit("ok", this.allShopList)
    },

    onInput(v){
      console.log("onInput", v)
    },
    onChange(v){
      console.log("onChange", v)
    },

  },
}
</script>

<style lang="less">
.group-item {
  // background: #eee;
  padding: 6px 10px;
  cursor: pointer;
  &-active{
    background: #eee;
  }
}
</style>