<template>
  <a-modal
    :visible.sync="show"
    title="管理网点的售卖商品"
    :footer="null"
    destroyOnClose
    width="800px"
    @cancel="$emit('update:show', false)"
  >
    <div class="search-bar">
      <a-form-model
        ref="searchRef"
        :model="searchForm"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <a-row>
          <div class="flex">
            <a-form-model-item style="width:160px;" label="类型" prop="type">
              <a-select style="width: 100%" v-model="searchForm.type" @change="search">
                <a-select-option value="0">全部</a-select-option>
                <a-select-option value="1">门店</a-select-option>
                <a-select-option value="2">配送站</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item style="width:160px;" label="状态" prop="status">
              <a-select style="width: 100%" v-model="searchForm.status" @change="search">
                <a-select-option value="0">全部</a-select-option>
                <a-select-option value="1">关店</a-select-option>
                <a-select-option value="2">未发布</a-select-option>
                <a-select-option value="3">营业</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item prop="name" label="门店名称" name="name">
              <a-input-search style="width:280px;" placeholder="请输入网点名" allowClear
                  v-model="searchForm.name" @search="search" />
            </a-form-model-item>
          </div>
        </a-row>
      </a-form-model>
    </div>
    <div class="flex">
      <div style="width:230px;">
        <div :class="activeGroupId == group.id ? 'group-item group-item-active' : 'group-item' "
          v-for="(group, index) in groupList"
          :key="index"
          :value="group.id"
          @click="selectGroup(group.id)"
        >{{ group.name }}</div>
      </div>
      <div style="flex:1;height:100%;">
        <base-table class="mb-3" 
                    rowKey="outlets_id"
                    :columnsData="baseColumns"
                    :tableData="groupShopList"
                    :total="total"
                    :getListFunc="getShopList"
                    :page.sync="searchForm.page"
                    :pageSize.sync="searchForm.page_count"
                    :addheight="-90">
            <template #operation="{record}">
              <a-button class="p-0" @click="selectShop(record)" type="link">查看</a-button>
            </template>
        </base-table>
      </div>
        
    </div>
      

  </a-modal>
</template>

<script>
import { formatOutletsType } from "@/utils/type"
import {
  getOutletsList,
  getGroupList,
} from "@/api/outlets"

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    adcode: {
      type: [String, Number],
      default: "",
    },
  },
  data () {
    return {
      wrapperCol: { span: 16 },
      labelCol: { span: 8 },
      searchForm: {
        outlets_city_adcode: this.adcode,
        group_id: "",
        name: "",
        type: "0",
        status: "3",
        page: 1,
        page_count: 40,
      },
      total: 0,

      baseColumns: [
        {
          title: '类型',
          dataIndex: 'type',
          align: 'center',
          width: "20%",
          slots: {
            customRender: 'type'
          },
          slotsType: 'format',
          slotsFunc: (val) => formatOutletsType(val)
        },
        {
          title: '网点名称',
          dataIndex: 'name',
          align: 'center',
          // width: 200,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: "20%",
          slots: { customRender: 'operation' },
          align: 'center'
        },
      ],
      columns: [],
      tableData: [],

      groupList: [],
      activeGroupId: "",
      groupShopList: [],
    }
  },

  mounted () {
    this.initData()
  },
  methods: {

    async initData () {
      await this.getGroupList()

      this.selectGroup("")
    },

    selectGroup(groupId){
      this.activeGroupId = groupId || "0"
      this.searchForm.group_id = this.activeGroupId

      this.search()
    },
    async search() {
      this.searchForm.page = 1
      this.getShopList()
    },
    reset(){
      this.searchForm.name = ""
      this.searchForm.type = "0"
      this.searchForm.status = "3"

      this.search()
    },
    async getShopList () {
      const { data, code } = await getOutletsList(this.searchForm)
      if (code == 0) {
        this.groupShopList = data.list
        this.total = data.total_count
      }
    },

    async getGroupList () {
      const { data, code } = await getGroupList({
        outlets_city_adcode: this.adcode
      })
      if (code == 0) {
        let groupList = data.list || []
        groupList.unshift({
          id: "0",
          name: "全部"
        })
        groupList.push({
          id: "-1",
          name: "未分组"
        })

        this.groupList = groupList
      }
    },

    selectShop(outlets){
      const newPage = this.$router.resolve({
        name: "shopSellDetail",
        query: { 
          outlets_id: outlets.outlets_id,
          outlets_type: outlets.type,
          outlets_name: outlets.name,
        },
      });
      window.open(newPage.href, "_blank");
    },

  },
}
</script>

<style lang="less">
.group-item {
  // background: #eee;
  padding: 6px 10px;
  cursor: pointer;
  &-active{
    background: #eee;
  }
}
</style>