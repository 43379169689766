<template>
  <a-modal
    :visible.sync="show"
    title="修改SKU库存"
    destroyOnClose
    :width="400"
    @ok="handleOk"
    @cancel="$emit('update:show', false)"
  >
    <div class="modal-search-bar">
      <a-form-model
        layout="horizontal"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <a-form-model-item v-if="!isCake" prop="current_stock" label="当前库存">
          <a-input-number style="width:100%;" :min="0" v-model="formData.current_stock" />
        </a-form-model-item>
        <a-form-model-item v-if="!isCake" prop="max_stock" label="每日库存">
          <a-input-number style="width:100%;" :min="0" v-model="formData.max_stock" />
        </a-form-model-item>
        <a-form-model-item v-if="isCake" prop="total_stock" label="剩余总库存">
          <!-- <a-checkbox v-model="isSetTotalStock">设置剩余总库存</a-checkbox> -->
          <a-radio-group name="isSetTotalStock" v-model="isSetTotalStock">
            <a-radio :value="1">不设置</a-radio>
            <a-radio :value="2">设置</a-radio>
          </a-radio-group>
          <a-input-number v-if="isSetTotalStock == 2" style="width:100px;" :min="0" v-model="formData.total_stock" />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import {
  editGoodsData,
  editGoodsLeftStock
} from "@/api/shop.js"

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    skuData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },

      formData: {
        outlets_id: "",
        sku_id: "",
        current_stock: 0,
        max_stock: 0,
        total_stock: 0,
      },

      isCake: false,
      isSetTotalStock: 1,
    }
  },
  mounted() {
    console.log("edit stock mounted", this.skuData)
    this.formData = {
      outlets_id: this.skuData.outlets_id,
      sku_id: this.skuData.sku_id,
      current_stock: this.skuData.stock,
      max_stock: this.skuData.daily_refresh_stock,
      total_stock: this.skuData.total_stock,
    }
    this.isCake = this.skuData.goods_type == 3
    if(this.isCake){
      this.isSetTotalStock = this.skuData.total_stock == -1 ? 1 : 2
    }
  },
  methods: {

    // 保存
    async handleOk() {
      if(this.isCake){
        if(this.isSetTotalStock == 1){
          const sku_data = {
            sku_id: this.formData.sku_id,
            // total_stock: this.formData.total_stock,
          }
          const { code } = await editGoodsLeftStock({
            outlets_id: this.formData.outlets_id,
            delete_sku_data: JSON.stringify([sku_data])
          })
          if(code == 0){
            this.$message.success("操作成功")
            this.$emit("ok")
            this.$emit("update:show", false)
          }
        }else{
          const sku_data = {
            sku_id: this.formData.sku_id,
            total_stock: this.formData.total_stock,
          }
          const { code } = await editGoodsLeftStock({
            outlets_id: this.formData.outlets_id,
            update_sku_data: JSON.stringify([sku_data])
          })
          if(code == 0){
            this.$message.success("操作成功")
            this.$emit("ok")
            this.$emit("update:show", false)
          }
        } 
      }else{
        const sku_data = {
          sku_id: this.formData.sku_id,
          current_stock: this.formData.current_stock,
          max_stock: this.formData.max_stock,
        }
        const { code } = await editGoodsData({
          outlets_id: this.formData.outlets_id,
          update_sku_data: JSON.stringify([sku_data])
        })
        if(code == 0){
          this.$message.success("操作成功")
          this.$emit("ok")
          this.$emit("update:show", false)
        }
      }
    },

  },
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
</style>