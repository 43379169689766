import { formatGoodsType, formatOutletsType } from '@/utils/type.js'

export const rowKey = 'goods_id'
export const columns = [ 
  {
    title: '产品属性',
    dataIndex: 'type',
    align: 'center',
    width: 90,
    slots: {
      customRender: 'type'
    },
    slotsType: 'format',
    slotsFunc: (val) => formatGoodsType(val)
  },
  {
    title: '商品名称',
    dataIndex: 'goods_name',
    align: 'center'
  },
  // {
  //   title: '小程序门店',
  //   dataIndex: 'is_on_sale_shop',
  //   align: 'center',
  //   width: "9%",
  //   slots: {
  //     customRender: 'is_on_sale_shop'
  //   },
  //   slotsType: 'format',
  //   slotsFunc: (val) => {return val==2?"在售":""}
  // },
  // {
  //   title: '小程序商城',
  //   dataIndex: 'is_on_sale_mall',
  //   align: 'center',
  //   width: "9%",
  //   slots: {
  //     customRender: 'is_on_sale_mall'
  //   },
  //   slotsType: 'format',
  //   slotsFunc: (val) => {return val==2?"在售":""}
  // },
  // {
  //   title: '网站',
  //   dataIndex: 'is_on_sale_website',
  //   align: 'center',
  //   width: "8%",
  //   slots: {
  //     customRender: 'is_on_sale_mall'
  //   },
  //   slotsType: 'format',
  //   slotsFunc: (val) => {return val==2?"在售":""}
  // },
  {
    title: '在售门店',
    align: 'center',
    width: "12%",
    slots: {
      customRender: 'on_sale_shop_number'
    },
    slotsType: 'all',
    slotsFunc: (item) => {
      if(item.on_sale_shop_number + item.not_on_sale_shop_number == 0) return 0
      else return item.on_sale_shop_number + " / " + (item.on_sale_shop_number + item.not_on_sale_shop_number)
    }
  },
  {
    title: '在售配送站',
    align: 'center',
    width: "12%",
    slots: {
      customRender: 'on_sale_delivery_station_number'
    },
    slotsType: 'all',
    slotsFunc: (item) => {
      if(item.type != 3) return "——"
      if(item.on_sale_delivery_station_number + item.not_on_sale_delivery_station_number == 0) return 0
      else return item.on_sale_delivery_station_number + " / " + (item.on_sale_delivery_station_number + item.not_on_sale_delivery_station_number)
    }
  },
  {
    title: "编辑上下架",
    dataIndex: "operation",
    align: "center",
    width: 100,
    slots: { customRender: "operation" },
  },
]

// 基本SKU列数据
// export const skuColumns = [
//   {
//     title: '口味',
//     dataIndex: 'flavor_name',
//     align: 'center',
//     slots: {
//       customRender: 'flavor_name'
//     },
//   },
//   {
//     title: '尺寸',
//     dataIndex: 'specification_name',
//     align: 'center',
//     width:120,
//     slots: {
//       customRender: 'specification_name'
//     },
//   },
//   {
//     title: 'SKU码',
//     dataIndex: 'sku_number',
//     align: 'center',
//     width:120,
//     slots: {
//       customRender: 'sku'
//     },
//   },
//   {
//     title: '默认价',
//     dataIndex: 'price',
//     width:120,
//     align: 'center',
//     slots: {
//       customRender: 'price'
//     },
//   },
//   {
//     title: '区域价',
//     dataIndex: 'area_price',
//     align: 'center',
//     width: 90,
//     slots: {
//       customRender: 'area_price'
//     }
//   },
//   {
//     title: '售卖',
//     dataIndex: 'status',
//     align: 'center',
//     width:70,
//     slots: {
//       customRender: 'status'
//     }
//   },
//   {
//     title: 'SKU图片\n600*430',
//     dataIndex: 'horizontal_image_path',
//     align: 'center',
//     width:130,
//     slots: {
//       customRender: 'horizontal_image_path'
//     }
//   }
// ]

// 蛋糕SKU列数据
// export const skuCakeColumns = [
//   {
//     title: '口味',
//     dataIndex: 'flavor_name',
//     align: 'center',
//     slots: {
//       customRender: 'flavor_name'
//     },
//   },
//   {
//     title: '尺寸',
//     dataIndex: 'specification_name',
//     align: 'center',
//     slots: {
//       customRender: 'specification_name'
//     },
//   },
//   {
//     title: 'SKU码',
//     dataIndex: 'sku_number',
//     align: 'center',
//     slots: {
//       customRender: 'sku'
//     },
//   },
//   {
//     title: '食用人数',
//     dataIndex: 'diners_number_config',
//     align: 'center',
//     width: 150,
//     slots: {
//       customRender: 'diners_number_config'
//     }
//   },
//   {
//     title: '默认价',
//     dataIndex: 'price',
//     align: 'center',
//     width:100,
//     slots: {
//       customRender: 'price'
//     },
//   },
//   {
//     title: '区域价',
//     dataIndex: 'area_price',
//     align: 'center',
//     width: 70,
//     slots: {
//       customRender: 'area_price'
//     }
//   },
//   {
//     title: '默认制作时间(h)',
//     dataIndex: 'production_hour',
//     align: 'center',
//     slots: {
//       customRender: 'production_hour'
//     }
//   },
//   {
//     title: '城市制作时间',
//     dataIndex: 'production_time_city',
//     align: 'center',
//     width:70,
//     slots: {
//       customRender: 'production_time_city'
//     }
//   },
//   {
//     title: 'SKU图片\n600*430',
//     dataIndex: 'horizontal_image_path',
//     align: 'center',
//     width:130,
//     slots: {
//       customRender: 'horizontal_image_path'
//     }
//   },
//   {
//     title: '售卖',
//     dataIndex: 'status',
//     align: 'center',
//     width: 70,
//     slots: {
//       customRender: 'status'
//     }
//   }
// ]


// 检查城市上架商品的库存
export const columns_stock = [
  {
    title: '产品属性',
    dataIndex: 'goods_type',
    align: 'center',
    width: 90,
    slots: {
      customRender: 'goods_type'
    },
    slotsType: 'format',
    slotsFunc: (val) => formatGoodsType(val)
  },
  {
    title: '商品名称',
    dataIndex: 'goods_name',
    align: 'center',
    width: "31%",
  },
  {
    title: '口味',
    dataIndex: 'flavor_name',
    align: 'center',
    width: "23%",
  },
  {
    title: '尺寸',
    dataIndex: 'specification_name',
    align: 'center',
    width: "23%",
  },
  {
    title: '网点类型',
    dataIndex: 'outlets_type',
    width: 90,
    align: 'center',
    slots: {
      customRender: 'outlets_type'
    },
    slotsType: 'format',
    slotsFunc: (val) => formatOutletsType(val)
  },
  {
    title: '网点名',
    dataIndex: 'outlets_name',
    align: 'center',
    width: "23%",
  },
  {
    title: '当前库存',
    dataIndex: 'stock',
    width: 90,
    align: 'center',
    slots: {
      customRender: 'stock'
    },
    slotsType: 'format',
    slotsFunc: (val) => { return val>999 ? "999" : val }
  },
  {
    title: '每日库存',
    dataIndex: 'daily_refresh_stock',
    width: 90,
    align: 'center',
    slots: {
      customRender: 'daily_refresh_stock'
    },
    slotsType: 'format',
    slotsFunc: (val) => { return val>999 ? "999" : val }
  },
  {
    title: '剩余总库存',
    dataIndex: 'total_stock',
    width: 100,
    align: 'center',
    slots: {
      customRender: 'total_stock'
    },
    slotsType: 'format',
    slotsFunc: (val) => { return val==-1 ? "未设置" : val }
  },
  {
    title: "编辑库存",
    dataIndex: "operation",
    align: "center",
    width: 90,
    slots: { customRender: "operation" },
  },
]

export default {
  rowKey,
  columns,
  columns_stock,
}
