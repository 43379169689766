<template>
  <a-modal
    :visible.sync="show"
    title="修改商品库存、在售的门店/配送站"
    destroyOnClose
    width="950px"
    @ok="handleOk"
    @cancel="$emit('update:show', false)"
  >
    <div class="mb-3">* 最大库存、当前库存：只会设置新上架的网点的库存、不影响之前已上架的网点。</div>

    <base-table ref="saleGoods" class="mb-3"
                id="saleGoods"
                rowKey="sku_id"
                :columnsData="columns"
                :tableData="tableData"
                :noPage="true"
                :addHeight="10">
      <template #max_stock="{record}">
        <a-input-number v-if="record.goods_type!=3" v-model="record.max_stock" @change="onChange" @input="onInput" />
      </template>
      <template #current_stock="{record}">
        <a-input-number v-if="record.goods_type!=3" v-model="record.current_stock" />
      </template>
    </base-table>

    <div v-if="goodItem" class="flex items-center">
      <span>在售门店: {{shopsOnSale}}/{{shopsTotal}}</span>
      <span v-if="goodItem.type == 3"
        class="ml-6">在售配送站: {{deliverOnSale}}/{{deliverTotal}}</span>
      <a-button class="ml-6" type="link" @click="handlerEdit">编辑</a-button>
    </div>

    <!-- <a-modal v-if="isShowMultiStock" :visible.sync="isShowMultiStock"
             title="将所有产品的库存全部修改为："
             destroyOnClose
             width="550px"
             @ok="handleMultiStockOk"
             @cancel="isShowMultiStock=false">
      <div class="flex justify-between items-center mt-4 mb-6">
        <div class="flex items-center">
          <span class="mr-3">每日最大库存</span>
          <a-input-number style="min-width:150px;" v-model="multi_max_stock" placeholder="每日最大库存" />
        </div>
        <div class="flex items-center">
          <span class="mr-3">当前库存</span>
          <a-input-number style="min-width:150px;" v-model="multi_current_stock" placeholder="今日剩余库存" />
        </div>
      </div>
    </a-modal> -->

    <!-- // test 在上架到部分门店的时候，可以设置门店分组 -->
    <edit-good-shops v-if="isShowEdit"
          :show.sync="isShowEdit"
          :adcode="adcode"
          :goodId="goodItem.goods_id"
          :isCake="goodItem.type == 3"
          @ok="handlerEditOK" />

  </a-modal>
</template>

<script>
import { formatGoodsType} from '@/utils/type.js'
import { rowSpan } from "@/utils/index"
import {
  getGoodsSkuList,
} from "@/api/regional/goods";
import EditGoodShops from "./edit-good-shops.vue"

import { cutQuery } from '@/utils/ossImg'

export default {
  components: {
    EditGoodShops,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    adcode: {
      type: [Number, String],
      default: "",
    },
    goodItem: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      baseColumns: [
        {
          title: "产品属性",
          dataIndex: "goods_type",
          align: "center",
          width: 80,
          customRender: (text, row) => {
            return {
              children: formatGoodsType(text),
              attrs: {
                rowSpan: row.goods_nameRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "图片",
          dataIndex: "cover_url",
          align: "center",
          width: 80,
          customRender: (text, row) => {
            if(text) text = text+cutQuery(50,50)
            return {
              children: <img style="height:50px;" src={text}></img>,
              attrs: {
                rowSpan: row.goods_nameRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.goods_nameRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "口味",
          dataIndex: "flavor_name",
          align: "center",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.flavor_nameRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "尺寸",
          dataIndex: "specification_name",
          align: "center",
        },
        {
          title: "最大库存",
          dataIndex: "max_stock",
          align: "center",
          width: 120,
          slots: { customRender: "max_stock" },
        },
        {
          title: "当前库存",
          dataIndex: "current_stock",
          align: "center",
          width: 120,
          slots: { customRender: "current_stock" },
        },
      ],
      columns: [],
      tableData: [],

      isShowMultiStock: false,
      multi_max_stock: 0,
      multi_current_stock: 0,

      shopsOnSale: this.goodItem.on_sale_shop_number, 
      shopsTotal:  this.goodItem.on_sale_shop_number + this.goodItem.not_on_sale_shop_number,
      deliverOnSale: this.goodItem.on_sale_delivery_station_number,
      deliverTotal:  this.goodItem.on_sale_delivery_station_number + this.goodItem.not_on_sale_delivery_station_number,
      isShowEdit: false,
      shopList: []
    }
  },

  mounted () {
    if(this.goodItem && this.goodItem.type == 3){
      this.columns = this.baseColumns.filter(el=>!["max_stock", "current_stock"].includes(el.dataIndex))
    }else{
      this.columns = this.baseColumns
    }

    this.initData()
  },
  methods: {

    async initData () {
      if(!this.goodItem) return;

      const { data, code } = await getGoodsSkuList({
        goods_ids: this.goodItem.goods_id
      })
      if (code == 0) {
        // 组装表格所需数据
        let skuArr = []
        // data.list.filter(gd=>gd.goods_type!=3).map((goods) => {
        data.list.map((goods) => {
          // goods.is_cake = goods.goods_type==3
          goods.sku_data.map((item) => {
            let obj = goods
                obj.flavor_name = item.flavor_name
                obj.status = 1
            // if (obj.is_cake) {
            //   obj.max_stock = "999+"
            // }
            if (item.specification_list.length > 0) {
              item.specification_list.map((el) => {
                obj = { ...obj, ...el }
                skuArr.push(obj)
              })
            }
          })
        })
        skuArr = rowSpan(skuArr, "goods_name")

        this.tableData = skuArr
      }
    },

    handleMultiStockOk(){
      this.tableData = this.tableData.map(sku=>{
        sku.max_stock     = this.multi_max_stock
        sku.current_stock = this.multi_current_stock
        return sku
      })
      this.isShowMultiStock = false
    },

    handleOk () {
      let self = this
      this.$confirm({
        title: "确定提交修改？",
        async onOk () {
          self.onComfirm()
        },
        onCancel () {
          console.log("Cancel")
        },
      })
    },

    handlerEdit(){
      this.isShowEdit = true
    },
    handlerEditOK(list){
      this.isShowEdit = false

      this.shopsOnSale = list.filter(el=>{
        return el.outlets_type == 1 && el.isOnSale
      }).length
      this.deliverOnSale = list.filter(el=>{
        return el.outlets_type == 2 && el.isOnSale
      }).length
      this.shopList = list
    },

    onInput(v){
      console.log("onInput", v)
    },
    onChange(v){
      console.log("onChange", v)
    },

    async onComfirm(){
      let isCake = this.goodItem.type == 3

      let other_sku_list
      if(!isCake){
        other_sku_list = this.tableData.map(sku=>{
          return {
             sku_id: sku.sku_id,
             max_stock: sku.max_stock||0,
             current_stock: sku.current_stock||0
          }
        })
      }
      
      // 网点
      let onIds = this.shopList.filter(shop=>{
        return shop.isOnSale
      }).map(el=>{
        return el.outlets_id
      })
      let offIds = this.shopList.filter(shop=>{
        return !shop.isOnSale
      }).map(el=>{
        return el.outlets_id
      })

      this.$emit("ok", {
        isCake: isCake,
        onIds: onIds,
        offIds: offIds,
        other_sku_list: other_sku_list
      })
    },
  },
}
</script>
